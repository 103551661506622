import { useQuery } from '@tanstack/react-query'
import { ITilsynsobjekt } from '../../../ducks/kvittering/tilsynsobjekt/types'
import {
  TidligereKontrollpunkterKeys,
  tidligereKontrollpunkterKeys,
} from '../queries/helpers'
import { ITidligereKontrollpunktDto } from '../types'
import { tidligereKontrollpunkterApi } from '../queries/tidligere-kontrollpunkter-api'

export { useTidligereKontrollpunkter }

const useTidligereKontrollpunkter = (tilsynsobjekter: ITilsynsobjekt[]) => {
  const aktiviteter = tilsynsobjekter.map((to) => to.aktivitetsId)

  return useQuery<
    ITidligereKontrollpunktDto[],
    unknown,
    ITidligereKontrollpunktDto[],
    ReturnType<TidligereKontrollpunkterKeys['aktiviteter']>
  >({
    queryKey: tidligereKontrollpunkterKeys.aktiviteter(aktiviteter),
    queryFn: async ({ queryKey: [{ aktiviteter }] }) => {
      const { data } = await tidligereKontrollpunkterApi.get(
        aktiviteter as string[]
      )
      return (
        data._embedded?.tilsynskvitteringKontrollpunktBeskrivelseDTOList ?? []
      )
    },
    staleTime: 5000,
    enabled: aktiviteter.length > 0,
  })
}
